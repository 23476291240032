import { cssVar } from 'utils/css';

export const pagination = {
  '&': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '@media (min-width: 37.5rem)': {
      flexDirection: 'row',
    },
    '&:not(:first-of-type)': {
      marginTop: '1.25rem',
    },
    '& > *:first-of-type': {
      marginBottom: '1.25rem',
      '@media (min-width: 37.5rem)': {
        marginBottom: 'auto',
      },
    },
    '& > *:last-child': {
      '& ul': {
        justifyContent: 'center',
      },
      '& li': {
        '&:not(:last-child)': {
          marginRight: '0.625rem',
          '@media (min-width: 37.5rem)': {
            marginRight: '1rem',
          },
        },
        '& > *': {
          backgroundColor: 'transparent',
          borderRadius: '0',
          color: cssVar('--primary'),
          fontSize: '0.875rem',
          height: 'auto',
          margin: '0',
          minWidth: '0',
          padding: '0',
          '&[aria-label*="previous"]': {
            border: '0.125rem solid var(--primary)',
            borderRadius: '2.75rem',
            height: '2.75rem',
            lineHeight: '2.5rem',
            textAlign: 'center',
            width: '2.75rem',
          },
          '&[aria-label*="next"]': {
            border: '0.125rem solid var(--primary)',
            borderRadius: '2.75rem',
            height: '2.75rem',
            lineHeight: '2.5rem',
            textAlign: 'center',
            width: '2.75rem',
          },
          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: cssVar('--primary-hover'),
            color: cssVar('--primary-hover'),
          },
          '&:active': {
            borderColor: cssVar('--primary-active'),
            color: cssVar('--primary-active'),
          },
          '&[class*="selected"]': {
            color: cssVar('--color-mine-shaft'),
          },
        },
      },
    },
  },
};
