import { TableRow, TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface NoResultsFoundProps {
  readonly colSpan: number;
}

export default function NoResultsFound({ colSpan }: NoResultsFoundProps) {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell colSpan={colSpan} align="center">
        {t('no_results_found')}
      </TableCell>
    </TableRow>
  );
}
